import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import {
  Container,
  FlexWrapper,
  H1,
  H2,
  Header,
  Image,
  ParallaxCache,
  PrimaryButton,
  Regular,
  SecondaryButton,
  Svg,
  TextColor,
  TextWrapper,
  Tiny,
} from 'components';
import { images } from 'utils/images';
import { mobile, useQuery } from 'styles/breakpoints';
import { primary, text70 } from 'styles/colors';

interface Props {
  onSelect: (gender: string) => void;
}

const Hero: React.FC<Props> = React.memo(({ onSelect }) => {
  const { isMobile } = useQuery();

  return (
    <Wrapper>
      <ParallaxCache />
      <Background>
        <Container>
          <Header />
          <H1
            textAlign="center"
            margin="0 auto"
            mobileFontSize="2.4rem"
            mobileLineHeight="3rem"
          >
            The Future of Healthy Cardiovascular System is
          </H1>
          <H1
            textAlign="center"
            color={primary}
            mobileFontSize="2.4rem"
            mobileLineHeight="3rem"
          >
            HERE
          </H1>
          <TextWrapper
            maxWidth="37rem"
            margin={isMobile ? '1.125rem auto 2.25rem' : '1.5rem auto 2.5rem'}
          >
            <Regular color={text70} textAlign="center">
              The perfect balance of science, nature, and technology. Prepare to
              feel your absolute best.
            </Regular>
          </TextWrapper>
          <Tiny textAlign="center">TAKE THE QUIZ</Tiny>
          <FlexWrapper
            alignItems="center"
            justifyContent="center"
            padding={isMobile ? '1.5rem 0 0' : '1.5rem 0 1.25rem'}
            width={isMobile ? '100%' : 'auto'}
          >
            <SecondaryButton
              maxWidth={isMobile ? 'unset' : '8.75rem'}
              margin="0 1.5rem 0 0"
              onClick={() => onSelect('female')}
              flex={isMobile ? '1' : ''}
            >
              FEMALE
            </SecondaryButton>
            <PrimaryButton
              maxWidth={isMobile ? 'unset' : '8.75rem'}
              onClick={() => onSelect('male')}
              flex={isMobile ? '1' : ''}
            >
              MALE
            </PrimaryButton>
          </FlexWrapper>
          <Parallax y={isMobile ? [20, -20] : [30, -30]}>
            <ParallaxPlaceholder />
            <PulsioBoxImage
              src={
                isMobile
                  ? 'pulsio_box_with_circle_mobile'
                  : 'pulsio_box_with_circle'
              }
            />
          </Parallax>
        </Container>
      </Background>
      <StyledContainer>
        <LeavesSvg
          src="icons/leaves_1"
          width="52"
          height="36"
          viewBox="0 0 52 36"
          fill="none"
        />
        <FlexWrapper
          alignItems="flex-end"
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
          padding={isMobile ? '0 0 2.875rem' : '0 2.125rem 7.875rem'}
        >
          <TextWrapper maxWidth="40rem">
            <H2 margin={isMobile ? '0.5rem 0 0.875rem' : '0.5rem 0 1.25rem'}>
              Cardiovascular system support –
              <br />
              <TextColor color={primary}>built just for you</TextColor>
            </H2>
            <TextWrapper maxWidth="24.125rem">
              <Regular color={text70}>
                Cardiovascular system treatment shouldn&apos;t be a guessing
                game. Consult with Pulsio cardiologists to get a personalized
                care that works for you.
              </Regular>
            </TextWrapper>
          </TextWrapper>
          <FlexWrapper
            maxWidth="21.688rem"
            alignItems="center"
            margin={isMobile ? '2.5rem auto 0' : null}
          >
            <Svg
              src="icons/promise"
              width="347"
              height="93"
              viewBox="0 0 347 93"
              fill="none"
            />
          </FlexWrapper>
        </FlexWrapper>
      </StyledContainer>
    </Wrapper>
  );
});

Hero.displayName = 'Hero';

export default Hero;

const Wrapper = styled.div`
  position: relative;
`;
const Background = styled.div`
  background-image: url(${images.hero_background});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
`;

const PulsioBoxImage = styled(Image)`
  position: absolute;
  right: 0;
  left: 20%;
  top: 20%;
  max-width: 55.563rem;
  @media ${mobile} {
    max-width: 23rem;
    left: 8%;
    top: 40%;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 10.75rem;
  @media ${mobile} {
    padding-top: 6rem;
  }
`;
const ParallaxPlaceholder = styled.div`
  width: 100%;
  height: 12.5rem;

  @media ${mobile} {
    height: 7.75rem;
  }
`;
const LeavesSvg = styled(Svg)`
  margin-left: 2rem;
  @media ${mobile} {
    margin-left: 0;
  }
`;
const ChangingTextWrapper = styled.div`
  position: relative;
  display: block;
  max-width: 28.125rem;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    max-width: 20.125rem;
  }
`;

const captionFadeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%)
  }
  25% {
    opacity: 1;
    transform: translateY(0%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  68% {
    opacity: 0;
    transform: translateY(100%);
  }
`;

const ChangeText = styled(H1)<{ index: number }>`
  display: inline-block;
  position: absolute;
  margin-left: 0.5rem !important;
  opacity: 0;
  animation: ${captionFadeAnimation} 4s ease-in infinite;

  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
`;
